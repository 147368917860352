.info-grid {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 20% auto;
    background-color: var(--color-dark);
    justify-items: center;
    max-width: 450px;
    max-height: 850px;
}

.app-container-info {
    height: 100%;
    width: 100%;
    background-color: var(--color-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}


.info-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    height: 75px;
    width: 200px;
    background-color: var(--color-light);
    border-radius: 40px;
    color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.info-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.info-to-home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: none;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 0;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    font-size: 40px;
}

.info-description {
    width: 85%;
    height: max-content;
    max-height: 76%;
    background-color: white;
    border-radius: 20px;
    overflow-y: scroll;

}

.info-description-header {
    text-align: center;
    margin: 20px;
    letter-spacing: 0.05em;
}

.info-list-item {
    margin: 25px;
    text-align: start;
}

@media only screen and (max-width: 350px) {

    .info-to-home {
        height: 50px;
        width: 50px;
        font-size: 30px;
    }
    .info-title {
        height: 60px;
    }
}
