.card,.card-special,.card-virus{
  position: absolute;
}

.card {
  display: flex;
  height: 100%;
  width: 80%;
  max-height: 450px;
  max-width: 80%;
  background-color: var(--color-red);
  border-radius: 3rem;
  color: white;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  flex-direction: column;
}
.card-info-1,
.card-info-2 {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  /*height: 30%;*/
  width: 80%;
  max-width: 80%;
  max-height: 90%;
  font-size: 1.4rem; /* TODO: make this scaling if possible */
  text-align: center;
}

.card-virus {
  display: flex;
  height: 100%;
  width: 80%;
  max-height: 100%;
  max-width: 80%;
  background-color: purple;
  border-radius: 3rem;
  color: white;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  flex-direction: column;
}

.card-special {
  display: flex;
  height: 100%;
  width: 80%;
  max-height: 100%;
  max-width: 80%;
  background-color: darkgreen;
  border-radius: 3rem;
  color: white;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  flex-direction: column;
}

@media only screen and (max-width: 500px) {
  .card-info-1, .card-info-2 {
    font-size: 5.2vw;
  }
}
