.game-grid {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 22% auto 22%;
  overflow: hidden;
  background-color: var(--color-light);
  max-width: 450px;
  max-height: 850px;
}

.app-container-game {
  height: 100%;
  width: 100%;
  background-color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.game-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 150px;
}

.exit-game {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: var(--color-red);
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  border: none;
  position: relative;
  padding: 0;
}

.game-id {
  height: 60px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-red);
  color: white;
  border-radius: 70px;
  font-size: 25px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.icon-size {
  color: white;
  visibility: initial;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 90%;
  width: 90%;
  transform: translate(-50%, -50%);
  display: block;
  padding: 0;
}

.game-card {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
  width: 80%;
  background-color: var(--color-red);
  color: white;
  border-radius: 70px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.toolbar-button-ready,
.toolbar-button {
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  background-color: white;
  border: none;
  border-radius: 100%;
  box-shadow: 10px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 1;
  padding: 0;
}

.toolbar-button {
  color: var(--color-red);
}

.toolbar-button-ready {
  color: green;
}

/*Virus colors*/

.exit-game-virus {
  background-color: var(--color-red);
}

.game-id-virus {
  background-color: var(--color-red);
  color: white;
}

.icon-size-virus {
  color: white;
}

.game-toolbar-virus {
  background-color: var(--color-red);
  color: white;
}

.toolbar-button-virus {
  background-color: white;
  color: var(--color-red);
}

.game-grid-virus {
  background-color: black;
}

@media only screen and (max-width: 350px) {

  .game-header {
    height: 100px;
  }
}
