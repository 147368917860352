.sign-in-grid,
.sign-up-grid {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-rows: 10% max-content max-content;
}

.sign-in-grid {
  z-index: 1;
}
.sign-up-grid {
  z-index: 2;
}
.sign-in-prompt {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-evenly;
  justify-items: center;
  height: 250px;
}

#sign-in-box {
  display: flex;
  justify-content: space-evenly;
}

.sign-in {
  font-size: 1.3em;
  height: 50px;
  width: 150px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: var(--color-dark);
  color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
}

.button-text {
  margin: 5px;
}

.sign-in ion-icon {
  margin: 3px;
}

.sign-in-input {
  height: 30px;
  width: 300px;
  border: none;
  font-size: 24px;
  background-color: var(--color-light);
  color: var(--color-red);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.sign-in-input::placeholder {
  color: var(--color-red);
}

input:focus {
  outline: none;
}

.route-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-reg a {
  color: var(--color-dark);
  justify-self: flex-end;
  text-align: end;
}
#sign-in-header {
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: var(--color-light);
  align-self: center;
}

.grid-reg {
  background-color: var(--color-red);
  display: grid;
  grid-template-rows: 180px 50px auto;
  grid-template-columns: 100%;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  max-width: 450px;
  /*max-height: 850px;*/
  overflow-y: auto;
}

.app-container-registration {
  height: 100%;
  width: 100%;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-reg {
  font-size: 48px;
  text-align: right;
  margin-right: 20px;
  font-weight: 400;
}

.header-reg {
  display: flex;
  height: 150px;
  width: 70%;
  font-size: 40px;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: flex-start;
  grid-row: 1;
  color: var(--color-dark);
}
.subHeader-reg {
  grid-row: 2;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.15em;
  color: var(--color-light);
  margin-right: 20px;
}
.subHeader-reg p {
  margin: 0;
}
.sign-up {
  font-size: 22px;
  height: 50px;
  width: 100px;
  border: none;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: var(--color-dark);
  color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.transition-box {
  width: 1500px;
  display: flex;
  justify-content: space-between;
}

.toggler-text, .fine-print {
  width: 300px;
  font-size: smaller;
  text-align: center;
  color: var(--color-light);
}

.toggler-text {
  padding-top: 5px;
}

.fine-print {
  padding-top: 10px;
}

@media only screen and (max-width: 350px) {
  .sign-in-input {
    width: 90%;
  }
  .sign-in {
    width: 120px;
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 500px) {
  .header-reg {
    align-items: center;
  }

  .grid-reg {
    grid-template-rows: 150px 50px auto;
  }
}
