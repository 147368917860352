.header {
  display: flex;
  height: 180px;
  width: 100%;
  font-size: 48px;
  justify-content: flex-end;
  justify-self: center;
  align-items: center;
  grid-row: 1;
  color: var(--color-dark);
}
.header p {
  margin: 20px;
}
.subHeader {
  grid-row: 2;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.15em;
  color: var(--color-light);
  margin-right: 20px;
}

.subHeader p {
  margin: 0;
}

.buttons {
  justify-content: space-evenly;
  width: auto;
  grid-row: 4;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  height: 250px;
}
a {
  display: grid;
  text-align: center;
  align-items: center;
  text-decoration: none;
}
.link1,
.link2 {
  width: 250px;
  height: 75px;
  border: none;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  font-size: 24px;
  border-radius: 70px;
  margin: 20px;
}
.link1 {
  background-color: var(--color-teal);
  color: var(--color-dark);
}
.link2 {
  background-color: var(--color-light);
  color: var(--color-red);
  margin-bottom: 50px;
}
.title {
  text-align: right;
  margin-right: 20px;
  font-size: 48px;
  font-weight: 400;
}

.grid,
.grid-blur {
  background-color: var(--color-red);
  display: grid;
  grid-template-rows: 180px 50px auto 300px;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  max-width: 450px;
  max-height: 850px;
}

.app-container-home {
  height: 100%;
  width: 100%;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-blur {
  filter: blur(5px);
}

.utilities {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.utilities-button {
  background-color: var(--color-light);
  color: var(--color-dark);
  /*margin-left: 20px;*/
  /*margin-right: 20px;*/
  height: 45px;
  width: 45px;
  border-radius: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: inset 3px 3px 5px #aeaeae;
  padding: 0;
}

@media only screen and (max-width: 350px) {
  .grid, .grid-blur {
    overflow-y: auto;
    grid-template-rows: 130px 70px auto 230px;
  }

  .buttons {
    height: 230px;
  }

  .header {
    height: 130px;
  }

  .link1, .link2 {
    margin: 10px;
    height: 60px;
    width: 70vw;
  }
  .subHeader p {
    font-size: 10px;
    width: 275px;
  }
}
