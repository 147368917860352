.list {
  list-style-type: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-of-players {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark);
  width: 90%;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  max-width: 400px;
}

.players:first-child {
  margin-top: 20px;
}

.players:last-child {
  margin-bottom: 20px;
}

.players {
  width: 90%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-teal);
  border-radius: 15px;
  margin: 10px;
  font-size: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.players p {
  padding-left: 15px;
}

.player-grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.hide-players {
  display: flex;
  height: 50px;
  width: 150px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: var(--color-dark);
  color: var(--color-teal);
  margin-top: 10px;
  border: none;
  font-size: 22px;
  padding: 0;
}
