.settings-grid {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 20% 15% 15% auto;
    background-color: var(--color-lighter);
    align-items: center;
    justify-items: center;
    max-width: 450px;
    max-height: 850px;
}

.app-container-settings {
    height: 100%;
    width: 100%;
    background-color: var(--color-lighter);
    display: flex;
    justify-content: center;
    align-items: center;
}
.settings-rows {
    display: flex;
    flex-direction: row;
    height: 80%;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    background-color: var(--color-light);
    border-radius: 10px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.settings-rows p {
    color: var(--color-dark);
    width: 200px;
    text-align: center;
}

.settings-button {
    border: none;
    border-radius: 10px;
    height: 60%;
    width: 120px;
    font-size: larger;
    color: var(--color-light);
    background-color: var(--color-dark);
    box-shadow: 9px 10px 50px 2px rgba(0, 0, 0, 0.25);
    padding: 0;
}

.settings-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    height: 75px;
    width: 200px;
    background-color: var(--color-light);
    border-radius: 40px;
    color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.settings-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.settings-to-home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: none;
    font-size: 30px;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 0;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    font-size: 40px;
}

.social {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    padding: 20px;
    background-color: var(--color-light);
    border-radius: 15px;
}

.social p {
    margin: 0;
}

.social-title {
    color: var(--color-light);
    font-size: 20px;
    text-align: center;
    padding: 10px;
    background-color: var(--color-dark);
    border-radius: 15px;
    width: 99%;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.25);
}

.social-items {
    color: var(--color-dark);
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 24px;
}

.social-list {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.social-items a {
    color: var(--color-dark);
    font-size: 28px;
}

@media only screen and (max-width: 350px) {
    .settings-rows p {
        width: 150px;
        font-size: 12px;
    }
    .settings-rows {
        height: 90%;
        margin: 10px;
    }
    .settings-to-home {
        height: 50px;
        width: 50px;
    }
    .settings-title {
        height: 60px;
    }
}
