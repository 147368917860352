.popup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    background-color: var(--color-dark);
    color: var(--color-teal);
    border-radius: 30px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    white-space: pre-wrap;
    max-width: 400px;
    min-width: 300px;
}

.popup p {
    max-width: 70%;
    text-align: center;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.popup-container {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 10;
}
