.leaderboard-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: x-large;
    height: 15%;
    color: var(--color-teal);
}

.leaderboard-list {
    background-color: var(--color-dark);
    height: 100%;
    width: 100%;
    max-width: 450px;
    max-height: 850px;
    /*border-radius: 30px;*/
    overflow-y: auto;
}

.app-container-leader {
    height: 100%;
    width: 100%;
    background-color: var(--color-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}
#ion-icon {
    margin: 0;
}

.exit-icon {
    visibility: initial;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90%;
    width: 90%;
    transform: translate(-50%, -50%);
    display: block;
    padding: 0;
}

.close-leaderboard {
    height: 50px;
    width: 50px;
    border-radius: 15px;
    border: none;
    margin: 0;
    background-color: var(--color-teal);
    padding: 0;
    position: relative;
}

.leaderboard-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 85%;
    font-size: 20px;
    color: var(--color-dark);
    background-color: var(--color-teal);
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.scrollable{
    overflow-x: auto;
    white-space: nowrap;
}

.leaderboard-item:first-child {
    margin-top: 0;
}

.leaderboard-title {
    color: var(--color-dark);
    background-color: var(--color-teal);
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 4px 10px;
    overflow-x: auto;
    white-space: nowrap;
}

.leaderboard-message {
    color: var(--color-dark);
    background-color: var(--color-teal);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    padding: 10px 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
    overflow-y: auto;
}

.leaderboard {
    display: flex;
    justify-content: center;
}

.list-leaderboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.leaderboard-top-3 {
    font-size: 25px;
    margin-left: 10px;
}

.leaderboard-item:first-child {
    background-color: #e9c504;
}

.leaderboard-item:nth-child(2) {
    background-color: #BEC2CB;
}

.leaderboard-item:nth-child(3) {
    background-color: #B08D57;
}

.medal {
    vertical-align: middle;
}

@media only screen and (max-width: 350px) {
    .close-leaderboard {
        height: 40px;
        width: 40px;
    }
    .leaderboard-item {
        font-size: 14px;
    }
}
