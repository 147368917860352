.grid-join {
    display: grid;
    height: 100%;
    width: 100%;
    justify-content: center;
    justify-items: center;
    grid-template-rows: 20% 375px auto;
    background-color: var(--color-light);
    max-width: 450px;
    max-height: 850px;
}

.app-container-join {
    height: 100%;
    width: 100%;
    background-color: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.header-join {
    align-self: center;
    height: 75px;
    width: 200px;
    background-color: var(--color-red);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.join-title {
    font-size: 24px;
    color: var(--color-light);
}

.join-input {
    height: 60px;
    width: 290px;
    border: none;
    font-size: 24px;
    background-color: var(--color-red);
    color: var(--color-light);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.join-input::placeholder {
    color: var(--color-light);
}

input:focus {
    outline: none;
}

.join-prompt {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-evenly;
    justify-items: center;
}

#join-box {
    display: flex;
    justify-content: flex-end;
}

.join {
    font-size: 24px;
    height: 60px;
    width: 140px;
    border: none;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    background-color: var(--color-light);
    color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-icon {
    margin-bottom: 5px;
}

.instructions p {
    display: flex;
    justify-content: center;
    max-width: 280px;
    font-size: 22px;
    text-align: center;
    color: var(--color-dark);
}

.header-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.back-join {
    display: flex;
    justify-content: center;
    height: 60px;
    background-color: var(--color-red);
    width: 60px;
    border-radius: 70px;
    font-size: 40px;
    color: var(--color-light);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

/* remove arrows on number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 350px) {

    .grid-join {
        overflow-y: auto;
    }
    .back-join {
        height: 50px;
        width: 50px;
    }
    .header-join {
        height: 60px;
    }
    .join-input {
        width: 80%;
        height: 12%;
    }
    .join {
        height: 40px;
        width: 50%;
    }
    .instructions p {
        font-size: 16px;
    }
}
