@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

:root {
  --color-red: #e63946;
  --color-dark: #1d3557;
  --color-light: #FAF9F9;
  --color-teal: #a8dadc;
  --color-lighter: #457b9d;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
}
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

#root {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
ion-icon {
  margin: 0;
}

button:focus{
  outline-style: none;
}

button {
  border: none;
}
