@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
.grid-join {
    display: grid;
    height: 100%;
    width: 100%;
    justify-content: center;
    justify-items: center;
    grid-template-rows: 20% 375px auto;
    background-color: var(--color-light);
    max-width: 450px;
    max-height: 850px;
}

.app-container-join {
    height: 100%;
    width: 100%;
    background-color: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.header-join {
    align-self: center;
    height: 75px;
    width: 200px;
    background-color: var(--color-red);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.join-title {
    font-size: 24px;
    color: var(--color-light);
}

.join-input {
    height: 60px;
    width: 290px;
    border: none;
    font-size: 24px;
    background-color: var(--color-red);
    color: var(--color-light);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.join-input::-webkit-input-placeholder {
    color: var(--color-light);
}

.join-input:-ms-input-placeholder {
    color: var(--color-light);
}

.join-input::-ms-input-placeholder {
    color: var(--color-light);
}

.join-input::placeholder {
    color: var(--color-light);
}

input:focus {
    outline: none;
}

.join-prompt {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-evenly;
    justify-items: center;
}

#join-box {
    display: flex;
    justify-content: flex-end;
}

.join {
    font-size: 24px;
    height: 60px;
    width: 140px;
    border: none;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    background-color: var(--color-light);
    color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-icon {
    margin-bottom: 5px;
}

.instructions p {
    display: flex;
    justify-content: center;
    max-width: 280px;
    font-size: 22px;
    text-align: center;
    color: var(--color-dark);
}

.header-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.back-join {
    display: flex;
    justify-content: center;
    height: 60px;
    background-color: var(--color-red);
    width: 60px;
    border-radius: 70px;
    font-size: 40px;
    color: var(--color-light);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

/* remove arrows on number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 350px) {

    .grid-join {
        overflow-y: auto;
    }
    .back-join {
        height: 50px;
        width: 50px;
    }
    .header-join {
        height: 60px;
    }
    .join-input {
        width: 80%;
        height: 12%;
    }
    .join {
        height: 40px;
        width: 50%;
    }
    .instructions p {
        font-size: 16px;
    }
}

.centered-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.flex-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.username-input-onboarding {
    height: 30px;
    width: 90%;
    border: none;
    font-size: 17px;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    grid-column: 1/3;
}


.username-prompt-header {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 40px;
    max-width: 650px;
}

.leaderboards-prompt-header {
    text-align: center;
    color: var(--color-light);
    justify-content: center;
    height: 75px;
    line-height: 25px;
    max-width: 81%;
    margin: 0;
    padding: 0;
}

.leaderboards-prompt-checkbox {
    color: var(--color-light);
    justify-content: flex-end;
    line-height: 25px;
    text-align: left;
    font-size: 15px;
    max-width: 85vw;
}

.add-username {
    display: grid;
    grid-template-columns: 70% 10%;
    grid-template-rows: 80px 100px;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.modal-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--color-lighter);
}

.checkbox-leaderboards {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: right;
    margin: 0px;
}

.on-boarding-button {
    height: 50px;
    margin: 10px;
    border-radius: 24px;
    border: none;
    font-size: 24px;
    padding: 10px;
    background-color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    color: var(--color-light);
}

.username-prompt-back {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: none;
    font-size: 30px;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 0;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    justify-self: center;
    margin-right: -5%;
}

.username-prompt-header-title {
    font-size: 48px;
    color: var(--color-dark);
    justify-self: center;
    text-align: center;
    margin-left: -25%;
}

@media only screen and (max-width: 350px) {
    .username-prompt-header {
        height: 15%;
    }

    .leaderboards-prompt-header {
        line-height: inherit;
        height: auto;
        margin: 10px;
    }

    .username-input-onboarding {
        height: 10%;
    }

    .add-username {
        grid-template-rows: 60px 80px;
    }

    .on-boarding-button {
        width: 80%;
        font-size: 20px;
    }
}

.header {
  display: flex;
  height: 180px;
  width: 100%;
  font-size: 48px;
  justify-content: flex-end;
  justify-self: center;
  align-items: center;
  grid-row: 1;
  color: var(--color-dark);
}
.header p {
  margin: 20px;
}
.subHeader {
  grid-row: 2;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.15em;
  color: var(--color-light);
  margin-right: 20px;
}

.subHeader p {
  margin: 0;
}

.buttons {
  justify-content: space-evenly;
  width: auto;
  grid-row: 4;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  height: 250px;
}
a {
  display: grid;
  text-align: center;
  align-items: center;
  text-decoration: none;
}
.link1,
.link2 {
  width: 250px;
  height: 75px;
  border: none;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  font-size: 24px;
  border-radius: 70px;
  margin: 20px;
}
.link1 {
  background-color: var(--color-teal);
  color: var(--color-dark);
}
.link2 {
  background-color: var(--color-light);
  color: var(--color-red);
  margin-bottom: 50px;
}
.title {
  text-align: right;
  margin-right: 20px;
  font-size: 48px;
  font-weight: 400;
}

.grid,
.grid-blur {
  background-color: var(--color-red);
  display: grid;
  grid-template-rows: 180px 50px auto 300px;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  max-width: 450px;
  max-height: 850px;
}

.app-container-home {
  height: 100%;
  width: 100%;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-blur {
  -webkit-filter: blur(5px);
          filter: blur(5px);
}

.utilities {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.utilities-button {
  background-color: var(--color-light);
  color: var(--color-dark);
  /*margin-left: 20px;*/
  /*margin-right: 20px;*/
  height: 45px;
  width: 45px;
  border-radius: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: inset 3px 3px 5px #aeaeae;
  padding: 0;
}

@media only screen and (max-width: 350px) {
  .grid, .grid-blur {
    overflow-y: auto;
    grid-template-rows: 130px 70px auto 230px;
  }

  .buttons {
    height: 230px;
  }

  .header {
    height: 130px;
  }

  .link1, .link2 {
    margin: 10px;
    height: 60px;
    width: 70vw;
  }
  .subHeader p {
    font-size: 10px;
    width: 275px;
  }
}

.host-grid, .host-grid-blur {
    display: grid;
    height: 100%;
    width: 100%;
    justify-content: center;
    justify-items: center;
    overflow: hidden;
    background-color: var(--color-teal);
    grid-template-rows: 20% -webkit-max-content;
    grid-template-rows: 20% max-content;
    grid-template-columns: 100%;
    max-width: 450px;
    max-height: 850px;
}

.app-container-host {
    height: 100%;
    width: 100%;
    background-color: var(--color-teal);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}


.host-grid-blur {
    -webkit-filter: blur(20px);
            filter: blur(20px);
    pointer-events: none;
}

.header-host {
    align-self: center;
    height: 75px;
    width: 200px;
    background-color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.host-title {
    font-size: 24px;
    color: var(--color-teal);
}

.game-id-host {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    font-size: larger;
    text-align: center;
}

.game-number {
    display: flex;
    height: 40px;
    width: 90px;
    align-items: center;
    justify-content: center;
    border-radius: 70px;
    background-color: var(--color-dark);
    color: var(--color-teal);
    border: none;
    padding: 0;
    font-size: 15px;
}

.host-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.start-game {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.start-button {
    height: 75px;
    width: 250px;
    color: var(--color-teal);
    background-color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: 30px;
    border: none;
}

.header-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.back-host {
    display: flex;
    justify-content: center;
    height: 60px;
    background-color: var(--color-dark);
    width: 60px;
    border-radius: 70px;
    font-size: 40px;
    color: var(--color-teal);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.welcome-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: x-large;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
    max-width: 85%;
}

#space {
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-name-prompt {
    width: 80%;
    height: 200px;
    background-color: var(--color-dark);
    color: var(--color-teal);
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    font-size: 20px;
    padding: 20px;
    max-width: 400px;
}

.host-name-input {
    height: 30px;
    width: 70%;
    border: none;
    font-size: 24px;
    background-color: var(--color-teal);
    color: var(--color-dark);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.host-name-input::-webkit-input-placeholder {
    color: var(--color-dark);
}

.host-name-input:-ms-input-placeholder {
    color: var(--color-dark);
}

.host-name-input::-ms-input-placeholder {
    color: var(--color-dark);
}

.host-name-input::placeholder {
    color: var(--color-dark);
}

.link-from-prompt {
    background-color: var(--color-teal);
    height: 50px;
    width: 100px;
    font-size: 20px;
    border-radius: 70px;
    color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 350px) {
    .back-host {
        height: 50px;
        width: 50px;
    }
    .header-host {
        height: 60px;
        width: 50%;
    }
    .host-title {
        font-size: 20px;
    }
    .game-id-host {
        width: 90%;
        margin-bottom: 0;
        font-size: 14px;
        height: 15vh;
    }
    .game-number {
        margin: 0;
    }
    .welcome-message {
        font-size: 16px;
        margin: 15px;
    }
}

.list {
  list-style-type: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-of-players {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark);
  width: 90%;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  max-width: 400px;
}

.players:first-child {
  margin-top: 20px;
}

.players:last-child {
  margin-bottom: 20px;
}

.players {
  width: 90%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-teal);
  border-radius: 15px;
  margin: 10px;
  font-size: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.players p {
  padding-left: 15px;
}

.player-grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.hide-players {
  display: flex;
  height: 50px;
  width: 150px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: var(--color-dark);
  color: var(--color-teal);
  margin-top: 10px;
  border: none;
  font-size: 22px;
  padding: 0;
}

.sign-in-grid,
.sign-up-grid {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-rows: 10% -webkit-max-content -webkit-max-content;
  grid-template-rows: 10% max-content max-content;
}

.sign-in-grid {
  z-index: 1;
}
.sign-up-grid {
  z-index: 2;
}
.sign-in-prompt {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-evenly;
  justify-items: center;
  height: 250px;
}

#sign-in-box {
  display: flex;
  justify-content: space-evenly;
}

.sign-in {
  font-size: 1.3em;
  height: 50px;
  width: 150px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: var(--color-dark);
  color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
}

.button-text {
  margin: 5px;
}

.sign-in ion-icon {
  margin: 3px;
}

.sign-in-input {
  height: 30px;
  width: 300px;
  border: none;
  font-size: 24px;
  background-color: var(--color-light);
  color: var(--color-red);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.sign-in-input::-webkit-input-placeholder {
  color: var(--color-red);
}

.sign-in-input:-ms-input-placeholder {
  color: var(--color-red);
}

.sign-in-input::-ms-input-placeholder {
  color: var(--color-red);
}

.sign-in-input::placeholder {
  color: var(--color-red);
}

input:focus {
  outline: none;
}

.route-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-reg a {
  color: var(--color-dark);
  justify-self: flex-end;
  text-align: end;
}
#sign-in-header {
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: var(--color-light);
  align-self: center;
}

.grid-reg {
  background-color: var(--color-red);
  display: grid;
  grid-template-rows: 180px 50px auto;
  grid-template-columns: 100%;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  max-width: 450px;
  /*max-height: 850px;*/
  overflow-y: auto;
}

.app-container-registration {
  height: 100%;
  width: 100%;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-reg {
  font-size: 48px;
  text-align: right;
  margin-right: 20px;
  font-weight: 400;
}

.header-reg {
  display: flex;
  height: 150px;
  width: 70%;
  font-size: 40px;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: flex-start;
  grid-row: 1;
  color: var(--color-dark);
}
.subHeader-reg {
  grid-row: 2;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.15em;
  color: var(--color-light);
  margin-right: 20px;
}
.subHeader-reg p {
  margin: 0;
}
.sign-up {
  font-size: 22px;
  height: 50px;
  width: 100px;
  border: none;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: var(--color-dark);
  color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.transition-box {
  width: 1500px;
  display: flex;
  justify-content: space-between;
}

.toggler-text, .fine-print {
  width: 300px;
  font-size: smaller;
  text-align: center;
  color: var(--color-light);
}

.toggler-text {
  padding-top: 5px;
}

.fine-print {
  padding-top: 10px;
}

@media only screen and (max-width: 350px) {
  .sign-in-input {
    width: 90%;
  }
  .sign-in {
    width: 120px;
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 500px) {
  .header-reg {
    align-items: center;
  }

  .grid-reg {
    grid-template-rows: 150px 50px auto;
  }
}

.game-grid {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 22% auto 22%;
  overflow: hidden;
  background-color: var(--color-light);
  max-width: 450px;
  max-height: 850px;
}

.app-container-game {
  height: 100%;
  width: 100%;
  background-color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.game-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 150px;
}

.exit-game {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: var(--color-red);
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  border: none;
  position: relative;
  padding: 0;
}

.game-id {
  height: 60px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-red);
  color: white;
  border-radius: 70px;
  font-size: 25px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.icon-size {
  color: white;
  visibility: visible;
  visibility: initial;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 90%;
  width: 90%;
  transform: translate(-50%, -50%);
  display: block;
  padding: 0;
}

.game-card {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
  width: 80%;
  background-color: var(--color-red);
  color: white;
  border-radius: 70px;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.toolbar-button-ready,
.toolbar-button {
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  background-color: white;
  border: none;
  border-radius: 100%;
  box-shadow: 10px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 1;
  padding: 0;
}

.toolbar-button {
  color: var(--color-red);
}

.toolbar-button-ready {
  color: green;
}

/*Virus colors*/

.exit-game-virus {
  background-color: var(--color-red);
}

.game-id-virus {
  background-color: var(--color-red);
  color: white;
}

.icon-size-virus {
  color: white;
}

.game-toolbar-virus {
  background-color: var(--color-red);
  color: white;
}

.toolbar-button-virus {
  background-color: white;
  color: var(--color-red);
}

.game-grid-virus {
  background-color: black;
}

@media only screen and (max-width: 350px) {

  .game-header {
    height: 100px;
  }
}

.card,.card-special,.card-virus{
  position: absolute;
}

.card {
  display: flex;
  height: 100%;
  width: 80%;
  max-height: 450px;
  max-width: 80%;
  background-color: var(--color-red);
  border-radius: 3rem;
  color: white;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  flex-direction: column;
}
.card-info-1,
.card-info-2 {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  /*height: 30%;*/
  width: 80%;
  max-width: 80%;
  max-height: 90%;
  font-size: 1.4rem; /* TODO: make this scaling if possible */
  text-align: center;
}

.card-virus {
  display: flex;
  height: 100%;
  width: 80%;
  max-height: 100%;
  max-width: 80%;
  background-color: purple;
  border-radius: 3rem;
  color: white;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  flex-direction: column;
}

.card-special {
  display: flex;
  height: 100%;
  width: 80%;
  max-height: 100%;
  max-width: 80%;
  background-color: darkgreen;
  border-radius: 3rem;
  color: white;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  flex-direction: column;
}

@media only screen and (max-width: 500px) {
  .card-info-1, .card-info-2 {
    font-size: 5.2vw;
  }
}

.seating-arrangement-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: x-large;
    height: 15%;
    color: var(--color-light);
}

.seating-arrangement {
    background-color: var(--color-dark);
    height: 100%;
    width: 100%;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
}

.seating-list {
  height: 70%;
  overflow: auto;
}

.seating-list-item:first-child {
    margin-top: 0;
}

.seating-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 40px;
  height: 45px;
  font-size: 30px;
  color: var(--color-dark);
  background-color: var(--color-teal);
  margin: 20px;
  border-radius: 15px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.seating-disclaimer {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: var(--color-teal);
    text-align: center;
}

.exit-icon {
    color: var(--color-dark) !important;
    visibility: visible;
    visibility: initial;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90%;
    width: 90%;
    transform: translate(-50%, -50%);
    display: block;
    padding: 0;
}

.back-to-game {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: none;
    background-color: var(--color-teal);
    padding: 0;
    position: relative;
}

.list-item-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    font-size: 30px;
    padding: 1rem;
}

@media only screen and (max-width: 350px) {
    .seating-arrangement-header {
        font-size: 18px;
    }

    .seating-list-item {
        padding-left: 20px;
        padding-right: 10px;
    }

    .seating-disclaimer {
        font-size: 20px;
        height: auto;
    }
}

.settings-grid {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 20% 15% 15% auto;
    background-color: var(--color-lighter);
    align-items: center;
    justify-items: center;
    max-width: 450px;
    max-height: 850px;
}

.app-container-settings {
    height: 100%;
    width: 100%;
    background-color: var(--color-lighter);
    display: flex;
    justify-content: center;
    align-items: center;
}
.settings-rows {
    display: flex;
    flex-direction: row;
    height: 80%;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    background-color: var(--color-light);
    border-radius: 10px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.settings-rows p {
    color: var(--color-dark);
    width: 200px;
    text-align: center;
}

.settings-button {
    border: none;
    border-radius: 10px;
    height: 60%;
    width: 120px;
    font-size: larger;
    color: var(--color-light);
    background-color: var(--color-dark);
    box-shadow: 9px 10px 50px 2px rgba(0, 0, 0, 0.25);
    padding: 0;
}

.settings-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    height: 75px;
    width: 200px;
    background-color: var(--color-light);
    border-radius: 40px;
    color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.settings-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.settings-to-home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: none;
    font-size: 30px;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 0;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    font-size: 40px;
}

.social {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    padding: 20px;
    background-color: var(--color-light);
    border-radius: 15px;
}

.social p {
    margin: 0;
}

.social-title {
    color: var(--color-light);
    font-size: 20px;
    text-align: center;
    padding: 10px;
    background-color: var(--color-dark);
    border-radius: 15px;
    width: 99%;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.25);
}

.social-items {
    color: var(--color-dark);
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 24px;
}

.social-list {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.social-items a {
    color: var(--color-dark);
    font-size: 28px;
}

@media only screen and (max-width: 350px) {
    .settings-rows p {
        width: 150px;
        font-size: 12px;
    }
    .settings-rows {
        height: 90%;
        margin: 10px;
    }
    .settings-to-home {
        height: 50px;
        width: 50px;
    }
    .settings-title {
        height: 60px;
    }
}

.header-redirect {
  display: flex;
  height: 180px;
  width: 100%;
  font-size: 48px;
  justify-content: flex-end;
  justify-self: center;
  align-items: center;
  grid-row: 1;
  color: var(--color-dark);
}
.subHeader-redirect {
  grid-row: 2;
  font-size: 18px;
  text-align: right;
  letter-spacing: 0.15em;
  color: var(--color-light);
  margin-right: 20px;

}
.subHeader-redirect p {
  margin: 0;
}
.title-redirect {
  text-align: right;
  margin-right: 20px;
  color: var(--color-dark);
  font-size: 48px;
  font-weight: 400;
}
.grid-redirect {
  background-color: var(--color-red);
  display: grid;
  grid-template-rows: 180px 50px auto;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  max-width: 450px;
  max-height: 850px;
}

.app-container-redirect {
  height: 100%;
  width: 100%;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    background-color: var(--color-dark);
    color: var(--color-teal);
    border-radius: 30px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    white-space: pre-wrap;
    max-width: 400px;
    min-width: 300px;
}

.popup p {
    max-width: 70%;
    text-align: center;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.popup-container {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 10;
}

.leaderboard-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: x-large;
    height: 15%;
    color: var(--color-teal);
}

.leaderboard-list {
    background-color: var(--color-dark);
    height: 100%;
    width: 100%;
    max-width: 450px;
    max-height: 850px;
    /*border-radius: 30px;*/
    overflow-y: auto;
}

.app-container-leader {
    height: 100%;
    width: 100%;
    background-color: var(--color-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}
#ion-icon {
    margin: 0;
}

.exit-icon {
    visibility: visible;
    visibility: initial;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90%;
    width: 90%;
    transform: translate(-50%, -50%);
    display: block;
    padding: 0;
}

.close-leaderboard {
    height: 50px;
    width: 50px;
    border-radius: 15px;
    border: none;
    margin: 0;
    background-color: var(--color-teal);
    padding: 0;
    position: relative;
}

.leaderboard-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 85%;
    font-size: 20px;
    color: var(--color-dark);
    background-color: var(--color-teal);
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.scrollable{
    overflow-x: auto;
    white-space: nowrap;
}

.leaderboard-item:first-child {
    margin-top: 0;
}

.leaderboard-title {
    color: var(--color-dark);
    background-color: var(--color-teal);
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 4px 10px;
    overflow-x: auto;
    white-space: nowrap;
}

.leaderboard-message {
    color: var(--color-dark);
    background-color: var(--color-teal);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    padding: 10px 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
    overflow-y: auto;
}

.leaderboard {
    display: flex;
    justify-content: center;
}

.list-leaderboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.leaderboard-top-3 {
    font-size: 25px;
    margin-left: 10px;
}

.leaderboard-item:first-child {
    background-color: #e9c504;
}

.leaderboard-item:nth-child(2) {
    background-color: #BEC2CB;
}

.leaderboard-item:nth-child(3) {
    background-color: #B08D57;
}

.medal {
    vertical-align: middle;
}

@media only screen and (max-width: 350px) {
    .close-leaderboard {
        height: 40px;
        width: 40px;
    }
    .leaderboard-item {
        font-size: 14px;
    }
}

.info-grid {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 20% auto;
    background-color: var(--color-dark);
    justify-items: center;
    max-width: 450px;
    max-height: 850px;
}

.app-container-info {
    height: 100%;
    width: 100%;
    background-color: var(--color-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}


.info-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    height: 75px;
    width: 200px;
    background-color: var(--color-light);
    border-radius: 40px;
    color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.info-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.info-to-home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: none;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 0;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    font-size: 40px;
}

.info-description {
    width: 85%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: 76%;
    background-color: white;
    border-radius: 20px;
    overflow-y: scroll;

}

.info-description-header {
    text-align: center;
    margin: 20px;
    letter-spacing: 0.05em;
}

.info-list-item {
    margin: 25px;
    text-align: start;
}

@media only screen and (max-width: 350px) {

    .info-to-home {
        height: 50px;
        width: 50px;
        font-size: 30px;
    }
    .info-title {
        height: 60px;
    }
}

:root{--color-red: #e63946;--color-dark: #1d3557;--color-light: #FAF9F9;--color-teal: #a8dadc;--color-lighter: #457b9d;-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}html{overflow:hidden;height:100%;width:100%;margin:0}body{overflow:hidden;height:100%;width:100%;margin:0;font-family:"Roboto", sans-serif}#root{overflow:hidden;height:100%;width:100%}ion-icon{margin:0}button:focus{outline-style:none}button{border:none}

