.seating-arrangement-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: x-large;
    height: 15%;
    color: var(--color-light);
}

.seating-arrangement {
    background-color: var(--color-dark);
    height: 100%;
    width: 100%;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
}

.seating-list {
  height: 70%;
  overflow: auto;
}

.seating-list-item:first-child {
    margin-top: 0;
}

.seating-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 40px;
  height: 45px;
  font-size: 30px;
  color: var(--color-dark);
  background-color: var(--color-teal);
  margin: 20px;
  border-radius: 15px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.seating-disclaimer {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: var(--color-teal);
    text-align: center;
}

.exit-icon {
    color: var(--color-dark) !important;
    visibility: initial;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90%;
    width: 90%;
    transform: translate(-50%, -50%);
    display: block;
    padding: 0;
}

.back-to-game {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: none;
    background-color: var(--color-teal);
    padding: 0;
    position: relative;
}

.list-item-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    font-size: 30px;
    padding: 1rem;
}

@media only screen and (max-width: 350px) {
    .seating-arrangement-header {
        font-size: 18px;
    }

    .seating-list-item {
        padding-left: 20px;
        padding-right: 10px;
    }

    .seating-disclaimer {
        font-size: 20px;
        height: auto;
    }
}
