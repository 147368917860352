.centered-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.flex-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
