.host-grid, .host-grid-blur {
    display: grid;
    height: 100%;
    width: 100%;
    justify-content: center;
    justify-items: center;
    overflow: hidden;
    background-color: var(--color-teal);
    grid-template-rows: 20% max-content;
    grid-template-columns: 100%;
    max-width: 450px;
    max-height: 850px;
}

.app-container-host {
    height: 100%;
    width: 100%;
    background-color: var(--color-teal);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}


.host-grid-blur {
    filter: blur(20px);
    pointer-events: none;
}

.header-host {
    align-self: center;
    height: 75px;
    width: 200px;
    background-color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.host-title {
    font-size: 24px;
    color: var(--color-teal);
}

.game-id-host {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    font-size: larger;
    text-align: center;
}

.game-number {
    display: flex;
    height: 40px;
    width: 90px;
    align-items: center;
    justify-content: center;
    border-radius: 70px;
    background-color: var(--color-dark);
    color: var(--color-teal);
    border: none;
    padding: 0;
    font-size: 15px;
}

.host-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.start-game {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.start-button {
    height: 75px;
    width: 250px;
    color: var(--color-teal);
    background-color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: 30px;
    border: none;
}

.header-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.back-host {
    display: flex;
    justify-content: center;
    height: 60px;
    background-color: var(--color-dark);
    width: 60px;
    border-radius: 70px;
    font-size: 40px;
    color: var(--color-teal);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.welcome-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: x-large;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
    max-width: 85%;
}

#space {
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-name-prompt {
    width: 80%;
    height: 200px;
    background-color: var(--color-dark);
    color: var(--color-teal);
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    font-size: 20px;
    padding: 20px;
    max-width: 400px;
}

.host-name-input {
    height: 30px;
    width: 70%;
    border: none;
    font-size: 24px;
    background-color: var(--color-teal);
    color: var(--color-dark);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

.host-name-input::placeholder {
    color: var(--color-dark);
}

.link-from-prompt {
    background-color: var(--color-teal);
    height: 50px;
    width: 100px;
    font-size: 20px;
    border-radius: 70px;
    color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 350px) {
    .back-host {
        height: 50px;
        width: 50px;
    }
    .header-host {
        height: 60px;
        width: 50%;
    }
    .host-title {
        font-size: 20px;
    }
    .game-id-host {
        width: 90%;
        margin-bottom: 0;
        font-size: 14px;
        height: 15vh;
    }
    .game-number {
        margin: 0;
    }
    .welcome-message {
        font-size: 16px;
        margin: 15px;
    }
}
