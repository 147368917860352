.username-input-onboarding {
    height: 30px;
    width: 90%;
    border: none;
    font-size: 17px;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    grid-column: 1/3;
}


.username-prompt-header {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 40px;
    max-width: 650px;
}

.leaderboards-prompt-header {
    text-align: center;
    color: var(--color-light);
    justify-content: center;
    height: 75px;
    line-height: 25px;
    max-width: 81%;
    margin: 0;
    padding: 0;
}

.leaderboards-prompt-checkbox {
    color: var(--color-light);
    justify-content: flex-end;
    line-height: 25px;
    text-align: left;
    font-size: 15px;
    max-width: 85vw;
}

.add-username {
    display: grid;
    grid-template-columns: 70% 10%;
    grid-template-rows: 80px 100px;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.modal-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--color-lighter);
}

.checkbox-leaderboards {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: right;
    margin: 0px;
}

.on-boarding-button {
    height: 50px;
    margin: 10px;
    border-radius: 24px;
    border: none;
    font-size: 24px;
    padding: 10px;
    background-color: var(--color-dark);
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    color: var(--color-light);
}

.username-prompt-back {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: none;
    font-size: 30px;
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: 0;
    box-shadow: 12px 18px 32px 2px rgba(0, 0, 0, 0.25);
    justify-self: center;
    margin-right: -5%;
}

.username-prompt-header-title {
    font-size: 48px;
    color: var(--color-dark);
    justify-self: center;
    text-align: center;
    margin-left: -25%;
}

@media only screen and (max-width: 350px) {
    .username-prompt-header {
        height: 15%;
    }

    .leaderboards-prompt-header {
        line-height: inherit;
        height: auto;
        margin: 10px;
    }

    .username-input-onboarding {
        height: 10%;
    }

    .add-username {
        grid-template-rows: 60px 80px;
    }

    .on-boarding-button {
        width: 80%;
        font-size: 20px;
    }
}
