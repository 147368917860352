.header-redirect {
  display: flex;
  height: 180px;
  width: 100%;
  font-size: 48px;
  justify-content: flex-end;
  justify-self: center;
  align-items: center;
  grid-row: 1;
  color: var(--color-dark);
}
.subHeader-redirect {
  grid-row: 2;
  font-size: 18px;
  text-align: right;
  letter-spacing: 0.15em;
  color: var(--color-light);
  margin-right: 20px;

}
.subHeader-redirect p {
  margin: 0;
}
.title-redirect {
  text-align: right;
  margin-right: 20px;
  color: var(--color-dark);
  font-size: 48px;
  font-weight: 400;
}
.grid-redirect {
  background-color: var(--color-red);
  display: grid;
  grid-template-rows: 180px 50px auto;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  max-width: 450px;
  max-height: 850px;
}

.app-container-redirect {
  height: 100%;
  width: 100%;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
}
